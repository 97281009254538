import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Logo from "../media/images/logo.png";
import logo_purple2 from '../media/images/logo_purple2.png';

import Blog1 from "../media/images/blog1.png"
import Blog2 from "../media/images/blog2.png"
import Blog3 from "../media/images/blog3.png"
import Blog4 from "../media/images/blog4.png";
import Blog5 from "../media/images/blog5.png"
import Blog6 from "../media/images/blog6.jpeg";

import Header3 from "../media/images/header3.jpg"
import RadioIcon from '../icons/radio';
import RadioActiveIcon from '../icons/radio-active';
import Swal from 'sweetalert2';

import emailjs from '@emailjs/browser';
import LanguageIcon from '../icons/language';
import LanguageSwitcher from './LanguageSwitcher';

<script src="https://cdn.jsdelivr.net/npm/sweetalert2@11"></script>

function AllArticles() {
    const navigate = useNavigate();
    const [lang, setLang] = useState('en');
  return (
   <>
            
<div className="topbar">
  <div className="topbar_wrapper">
    <div className="left_topbar">
      <a target="_blank" href="https://www.instagram.com/diravenir?igsh=YndyOTdpd2Zkb292&utm_source=qr" className="topbar_item">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24.005"
          viewBox="918 1559 24 24.005"
        >
          <g fillRule="evenodd" data-name="Group 34">
            <path
              d="M919.565 1560.667c-1.88 1.953-1.495 4.028-1.495 10.332 0 5.235-.914 10.483 3.866 11.718 1.493.384 14.72.384 16.21-.002 1.99-.514 3.61-2.128 3.83-4.943.032-.393.032-13.147 0-13.548-.236-2.998-2.081-4.726-4.513-5.076-.558-.08-.67-.105-3.53-.11-10.143.005-12.366-.446-14.368 1.629z"
              data-name="Path 19"
            ></path>
            <path
              fill="#ffd355"
              d="M930.033 1562.177c-3.617 0-7.051-.321-8.363 3.045-.542 1.39-.463 3.197-.463 5.783 0 2.269-.073 4.402.463 5.781 1.309 3.369 4.771 3.046 8.361 3.046 3.464 0 7.035.36 8.363-3.046.542-1.404.463-3.183.463-5.781 0-3.449.19-5.675-1.482-7.346-1.694-1.694-3.984-1.482-7.346-1.482zm-.79 1.591c7.544-.012 8.504-.85 7.974 10.8-.188 4.122-3.326 3.67-7.183 3.67-7.032 0-7.234-.202-7.234-7.237 0-7.117.557-7.229 6.442-7.235zm5.502 1.465a1.059 1.059 0 100 2.118 1.059 1.059 0 000-2.118zm-4.712 1.238a4.533 4.533 0 100 9.066 4.533 4.533 0 000-9.066zm0 1.591c3.89 0 3.895 5.885 0 5.885-3.889 0-3.895-5.885 0-5.885z"
              data-name="Path 20"
            ></path>
          </g>
        </svg>
        <span></span>
      </a>
      <a target="_blank" href="https://www.linkedin.com/company/diravenir/" className="topbar_item">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="1081 1559 24 24"
        >
          <g data-name="Group 37">
            <path
              fillRule="evenodd"
              d="M1101.727 1559h-17.454a3.273 3.273 0 00-3.273 3.273v17.454a3.273 3.273 0 003.273 3.273h17.454a3.273 3.273 0 003.273-3.273v-17.454a3.273 3.273 0 00-3.273-3.273zm-12.545 18.993a.506.506 0 01-.506.507h-2.156a.506.506 0 01-.507-.507v-9.038c0-.28.227-.508.507-.508h2.156c.28.001.506.228.506.508v9.038zm-1.585-10.402a2.045 2.045 0 110-4.091 2.045 2.045 0 010 4.09zm12.854 10.437a.465.465 0 01-.466.467h-2.319a.465.465 0 01-.466-.467v-4.234c0-.633.185-2.77-1.654-2.77-1.425 0-1.716 1.462-1.773 2.12v4.89a.466.466 0 01-.46.466h-2.238a.465.465 0 01-.465-.466v-9.119a.465.465 0 01.465-.466h2.239c.257 0 .466.208.466.466v.788c.53-.795 1.313-1.406 2.986-1.406 3.707 0 3.682 3.461 3.682 5.362l.003 4.37z"
              data-name="Layer 2"
            ></path>
          </g>
        </svg>
        <span></span>
      </a>
      <a target="_blank" href="https://web.facebook.com/profile.php?id=61553731883991&locale=fr_FR" className="topbar_item">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M22.675 0h-21.35C.597 0 0 .597 0 1.325v21.351C0 23.404.597 24 1.325 24H12.82V14.709h-3.18V11.24h3.18V8.412c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24H17.4c-1.386 0-1.654.66-1.654 1.623v2.13h3.307l-.432 3.468h-2.875V24h5.632C23.403 24 24 23.404 24 22.675V1.325C24 .597 23.403 0 22.675 0z" />
        </svg>
        <span></span>
      </a>
    </div>
    <div className="right_topbar">
      <div className="topbar_item">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="#5f6368"
          viewBox="0 -960 960 960"
        >
          <path d="M798-120q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12z"></path>
        </svg>
        <span>+212 718-565283</span>
      </div>
      <div className="topbar_item topbar_item_lang">
      <LanguageSwitcher setLang={setLang} />

      </div>
      <div className="topbar_item">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="#5f6368"
          viewBox="0 -960 960 960"
        >
          <path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160zm320-287q5 0 10.5-1.5T501-453l283-177q8-5 12-12.5t4-16.5q0-20-17-30t-35 1L480-520 212-688q-18-11-35-.5T160-659q0 10 4 17.5t12 11.5l283 177q5 3 10.5 4.5T480-447z"></path>
        </svg>
        <span>contact@diravenir.com</span>
      </div>
     
    </div>
  </div>
</div>
<div className="navbar">
        <div className="navbar_wrapper">
        <Link to="/" className="logo_container">
           <img src={Logo} />
        </Link>
        <div className="nav_links">
            <Link to="#about">{lang === "en" ? "About Us" : "À propos"}</Link>
            <Link to="#services">{lang === "en" ? "Our Services" : "Nos services"}</Link>
            <Link to="/blog">Blog</Link>
        </div>
          <Link to="/postuler" className="nav_btn">{lang == "en" ? "Apply" : "Postuler"}</Link>
        </div>
      </div>
      <div className="blog_container2">
  <Link to="/article/studying-abroad-why-it-matters-more-than-ever" className="article_item2">
    <div className="article_img2" style={{ backgroundImage: `url('https://i.ibb.co/PMdX5k0/blog1.png')` }}></div>
    <div className="article_title2">{lang === "en" ? "Studying Abroad: Why It Matters More Than Ever ?" : "Étudier à l'étranger : Pourquoi cela compte plus que jamais ?"}</div>
    <div className="article_read2">{lang === "en" ? "Studying abroad is more than just a trend or a checkbox on a resume; it's a transformative experience that can shape the trajectory..." : "Étudier à l'étranger n'est pas seulement une tendance ou une case à cocher sur un CV ; c'est une expérience transformatrice qui peut façonner la trajectoire..."}</div>
    <div className="article_action2">
      <span>{lang === "en" ? "Go to article" : "Consulter l'article"}</span>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -960 960 960">
        <path d="M504-480L320-664l56-56 240 240-240 240-56-56 184-184z"></path>
      </svg>
    </div>
  </Link>
  <Link to="/article/8-reasons-to-study-in-turkish-cyprus-as-a-moroccan-student" className="article_item2">
    <div className="article_img2" style={{ backgroundImage: `url('https://i.ibb.co/Xz8PQq7/blog3.png')` }}></div>
    <div className="article_title2">{lang === "en" ? "8 Reasons to Study in Turkish Cyprus as a Moroccan Student" : "8 Raisons d'Étudier à Chypre Turque en tant qu'Étudiant Marocain"}</div>
    <div className="article_read2">{lang === "en" ? "In the realm of international education, there are destinations that shine brightly on the global stage, drawing students from  and... " : "Dans le domaine de l'éducation internationale, il existe des destinations qui brillent sur la scène mondiale, attirant des étudiants de loin. Pourtant, parmi les pôles éducatifs bien connus, se trouve..."}</div>
    <div className="article_action2">
      <span>{lang === "en" ? "Go to article" : "Consulter l'article"}</span>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -960 960 960">
        <path d="M504-480L320-664l56-56 240 240-240 240-56-56 184-184z"></path>
      </svg>
    </div>
  </Link>
  <Link to="/article/8-reasons-for-Moroccan-to-study-abroad-in-china" className="article_item2">
    <div className="article_img2" style={{ backgroundImage: `url('https://i.ibb.co/rykXjyz/blog2.png')` }}></div>
    <div className="article_title2">{lang === "en" ? "8 Reasons for Moroccans to Study Abroad in China" : "8 raisons pour les Marocains d'étudier à l'étranger en Chine"}</div>
    <div className="article_read2">{lang === "en" ? "For Moroccan students with dreams as vast as the Sahara, the prospect of studying abroad is like embarking on a journey across uncharted territories..." : "Pour les étudiants marocains dont les rêves sont aussi vastes que le Sahara, la perspective d'étudier à l'étranger s'apparente à un voyage à travers des territoires inexplorés..."}</div>
    <div className="article_action2">
      <span>{lang === "en" ? "Go to article" : "Consulter l'article"}</span>
      <svg className="social" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -960 960 960">
        <path d="M504-480L320-664l56-56 240 240-240 240-56-56 184-184z"></path>
      </svg>
    </div>
  </Link>
  <Link to="/article/Experience-World-Class-Education-in-China" className="article_item2">
    <div className="article_img2" style={{ backgroundImage: `url('https://i.ibb.co/VNWRbXY/blog4.png')` }}></div>
    <div className="article_title2">{lang === "en" ? "Experience World-Class Education in China" : "Expérimentez une éducation de classe mondiale en Chine"}</div>
    <div className="article_read2">{lang === "en" ? "Choosing the right university for your international studies is a pivotal decision that can significantly influence your academic and professional future..." : "Choisir la bonne université pour vos études internationales est une décision cruciale qui peut influencer considérablement votre avenir académique et professionnel. La Chine.."}</div>
    <div className="article_action2">
      <span>{lang === "en" ? "Go to article" : "Consulter l'article"}</span>
      <svg className="social" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -960 960 960">
        <path d="M504-480L320-664l56-56 240 240-240 240-56-56 184-184z"></path>
      </svg>
    </div>
  </Link>
  <Link to="/article/Discover-Top-Universities-in-Northern-Cyprus" className="article_item2">
    <div className="article_img2" style={{ backgroundImage: `url('https://i.ibb.co/J7gfmyp/blog5.png')` }}></div>
    <div className="article_title2">{lang === "en" ? "Discover Top Universities in Northern Cyprus" : "Découvrez les meilleures universités dans le nord de Chypre"}</div>
    <div className="article_read2">{lang === "en" ? "Cyprus, an island nation in the Mediterranean, has become a popular destination for international students seeking quality education.." : "Chypre, une nation insulaire située en Méditerranée, est devenue une destination prisée pour les étudiants internationaux à la recherche d'une éducation de qualité. Le pays propose une gamme diversifiée..."}</div>
    <div className="article_action2">
      <span>{lang === "en" ? "Go to article" : "Consulter l'article"}</span>
      <svg className="social" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -960 960 960">
        <path d="M504-480L320-664l56-56 240 240-240 240-56-56 184-184z"></path>
      </svg>
    </div>
  </Link>
  <Link to="/article/Learning-Chinese-as-a-student-is-that-possible" className="article_item2">
    <div className="article_img2" style={{ backgroundImage: `url('https://i.ibb.co/kmQW8yg/blog6.jpg')` }}></div>
    <div className="article_title2">{lang === "en" ? "Learning Chinese as a student, is that possible?" : "Apprendre le chinois en tant qu'étudiant, est-ce possible?"}</div>
    <div className="article_read2">{lang === "en" ? "In today’s globalized world, learning a new language can open doors to a multitude of opportunities. Among the many languages..." : "Dans le monde globalisé d'aujourd'hui, apprendre une nouvelle langue peut ouvrir les portes à de nombreuses opportunités. Parmi les nombreuses langues disponibles..."}</div>
    <div className="article_action2">
      <span>{lang === "en" ? "Go to article" : "Consulter l'article"}</span>
      <svg className="social" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -960 960 960">
        <path d="M504-480L320-664l56-56 240 240-240 240-56-56 184-184z"></path>
      </svg>
    </div>
  </Link>

  
 
  
  </div>
  <div className="footer">
        <div className="footer_wrapper">
          <div className="footer_logo">
            <div className="logo_container">
            <img src={logo_purple2} />
            </div>
            <div className="footer_vert_info">
              
            <a target="_blank" href="https://www.instagram.com/diravenir?igsh=YndyOTdpd2Zkb292&utm_source=qr" className="topbar_item">
        <svg className="social"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24.005"
          viewBox="918 1559 24 24.005"
        >
          <g fillRule="evenodd" data-name="Group 34">
            <path
              d="M919.565 1560.667c-1.88 1.953-1.495 4.028-1.495 10.332 0 5.235-.914 10.483 3.866 11.718 1.493.384 14.72.384 16.21-.002 1.99-.514 3.61-2.128 3.83-4.943.032-.393.032-13.147 0-13.548-.236-2.998-2.081-4.726-4.513-5.076-.558-.08-.67-.105-3.53-.11-10.143.005-12.366-.446-14.368 1.629z"
              data-name="Path 19"
            ></path>
            <path
              fill="#ffd355"
              d="M930.033 1562.177c-3.617 0-7.051-.321-8.363 3.045-.542 1.39-.463 3.197-.463 5.783 0 2.269-.073 4.402.463 5.781 1.309 3.369 4.771 3.046 8.361 3.046 3.464 0 7.035.36 8.363-3.046.542-1.404.463-3.183.463-5.781 0-3.449.19-5.675-1.482-7.346-1.694-1.694-3.984-1.482-7.346-1.482zm-.79 1.591c7.544-.012 8.504-.85 7.974 10.8-.188 4.122-3.326 3.67-7.183 3.67-7.032 0-7.234-.202-7.234-7.237 0-7.117.557-7.229 6.442-7.235zm5.502 1.465a1.059 1.059 0 100 2.118 1.059 1.059 0 000-2.118zm-4.712 1.238a4.533 4.533 0 100 9.066 4.533 4.533 0 000-9.066zm0 1.591c3.89 0 3.895 5.885 0 5.885-3.889 0-3.895-5.885 0-5.885z"
              data-name="Path 20"
            ></path>
          </g>
        </svg>
        <span>diravenir</span>
      </a>
              <a target="_blank"  href="https://www.linkedin.com/company/diravenir/" className="topbar_item">
                <svg className="social"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="1081 1559 24 24"
                  
                  
                >
                  <g data-name="Group 37">
                    <path
                      fill="#000000"
                      fillRule="evenodd"
                      d="M1101.727 1559h-17.454a3.273 3.273 0 00-3.273 3.273v17.454a3.273 3.273 0 003.273 3.273h17.454a3.273 3.273 0 003.273-3.273v-17.454a3.273 3.273 0 00-3.273-3.273zm-12.545 18.993a.506.506 0 01-.506.507h-2.156a.506.506 0 01-.507-.507v-9.038c0-.28.227-.508.507-.508h2.156c.28.001.506.228.506.508v9.038zm-1.585-10.402a2.045 2.045 0 110-4.091 2.045 2.045 0 010 4.09zm12.854 10.437a.465.465 0 01-.466.467h-2.319a.465.465 0 01-.466-.467v-4.234c0-.633.185-2.77-1.654-2.77-1.425 0-1.716 1.462-1.773 2.12v4.89a.466.466 0 01-.46.466h-2.238a.465.465 0 01-.465-.466v-9.119a.465.465 0 01.465-.466h2.239c.257 0 .466.208.466.466v.788c.53-.795 1.313-1.406 2.986-1.406 3.707 0 3.682 3.461 3.682 5.362l.003 4.37z"
                      data-name="Layer 2"
                    ></path>
                  </g>
                </svg>
                <span>diravenir</span>
              </a>
              <a target="_blank" href="https://web.facebook.com/profile.php?id=61553731883991&locale=fr_FR" className="topbar_item">
        <svg className="social"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M22.675 0h-21.35C.597 0 0 .597 0 1.325v21.351C0 23.404.597 24 1.325 24H12.82V14.709h-3.18V11.24h3.18V8.412c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24H17.4c-1.386 0-1.654.66-1.654 1.623v2.13h3.307l-.432 3.468h-2.875V24h5.632C23.403 24 24 23.404 24 22.675V1.325C24 .597 23.403 0 22.675 0z" />
        </svg>
        <span>Diravenir</span>
      </a>



            </div>

          </div>

          <div className="footer_location">
            <h4>{lang == "en" ? "Our location" : "Notre emplacement"}</h4>
            <p>179, Angle des Boulevards de Londres et de la Résistance, Quartier, Av. Mers Sultan,<br />Casablanca20250</p>
          </div>

          <div className="footer_contact">
            <h4>{lang == "en" ? "Contact Us" : "Contactez nous"}</h4> 
            <div className="footer_vert_info">
              <div className="topbar_item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#5f6368"
                  viewBox="0 -960 960 960"
                >
                  <path d="M798-120q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12z"></path>
                </svg>
               <p> <span>+212 718-565283</span></p> 
              </div>
              <div className="topbar_item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#5f6368"
                  viewBox="0 -960 960 960"
                >
                  <path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160zm320-287q5 0 10.5-1.5T501-453l283-177q8-5 12-12.5t4-16.5q0-20-17-30t-35 1L480-520 212-688q-18-11-35-.5T160-659q0 10 4 17.5t12 11.5l283 177q5 3 10.5 4.5T480-447z"></path>
                </svg>
                <p><span>contact@diravenir.com</span></p>
              </div>
            </div>
          </div>
        </div>
     
     
      </div>
   
   
   </>
  )
}

export default AllArticles