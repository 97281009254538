import React, { useState } from 'react';

const flags = [
  {
    code: "fr",
    name: "Français",
    flag: "https://flagcdn.com/w40/fr.png",
  },
  {
    code: "en",
    name: "English",
    flag: "https://flagcdn.com/w40/gb.png",
  },
];

const LanguageSwitcher = ({ setLang }) => {
  const [selectedLang, setSelectedLang] = useState(flags[1]); // Default to English
  const [isOpen, setIsOpen] = useState(false); // State to control dropdown visibility

  const handleChange = (code) => {
    const selectedFlag = flags.find(flag => flag.code === code);
    setLang(code);
    setSelectedLang(selectedFlag);
    setIsOpen(false); // Close the dropdown after selection
  };

  const toggleDropdown = () => {
    setIsOpen(prev => !prev); // Toggle dropdown visibility
  };

  return (
    <div className="topbar_item topbar_item_lang">
      <div style={{ position: 'relative', display: 'inline-block', backgroundColor: '#ffd355', padding: '5px' }}>
        <button onClick={toggleDropdown} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', background: 'transparent', border: 'none' }}>
          <img src={selectedLang.flag} alt={selectedLang.name} style={{ width: '20px', height: '20px', marginRight: '8px' }} />
          {!isOpen && selectedLang.name} {/* Only show the language name when dropdown is closed */}
        </button>
        {isOpen && ( // Render dropdown only if it's open
          <div style={{ 
            position: 'absolute', 
            backgroundColor: '#f9f5ff', 
            border: 'none', 
            zIndex: 1 
          }}>
            {flags.map(flag => (
              <div 
                key={flag.code} 
                onClick={() => handleChange(flag.code)} 
                style={{ padding: '8px', cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#ffd355'} // Light hover effect
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'} // Reset on leave
              >
                <img src={flag.flag} alt={flag.name} style={{ width: '20px', height: '20px', marginRight: '8px' }} />
                {flag.name}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default LanguageSwitcher;
